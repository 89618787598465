import Cookies from 'js-cookie'
import React, { useState } from 'react'
import { postRequest } from '../../utility/apiRequest'
import { NavLink, useNavigate } from 'react-router-dom'
import { HiArrowLeft } from 'react-icons/hi'

interface AlertProps {
    type: 'success' | 'error'
    message: string
}
const ForgotPassword: React.FC = () => {
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [alert, setAlert] = useState<AlertProps | null>(null)
    const navigate = useNavigate()

    const handleForgotPassword = async () => {
        localStorage.setItem('email', email)
        try {
            setLoading(true)
            setAlert(null)

            if (!email) {
                setAlert({
                    type: 'error',
                    message: 'Please enter your email address'
                })
                return
            }

            const result = await postRequest('forget-account-sendotp', { email })

            if (result.data.success) {
                setAlert({
                    type: 'success',
                    message: `${result.data.message} Please check your phone for the OTP.`
                })

                // Reset form
                setEmail('')

                // Navigate after delay
                setTimeout(() => {
                    navigate('/reset-password')
                }, 3000)
            } else {
                setAlert({
                    type: 'error',
                    message: result.data.message || 'Failed to send OTP. Please try again.'
                })
            }
        } catch (error) {
            setAlert({
                type: 'error',
                message: 'An error occurred. Please try again later.'
            })
        } finally {
            setLoading(false)
        }
    }
    return (
        <>
            <div className="verification py-5">
                <div className="container py-5">
                    <div className="row justify-content-center align-items-center text-center py-5">
                        <div className="col-7">
                            <img src="/assets/lock.png" alt="logo" className="img-fluid mb-4" width={'60px'} />
                        </div>
                        <h3 className="signup__col--title mb-3">Forget password?</h3>
                        <p className="pb-5">Enter the email linked to your account</p>
                        <div className=" col-md-5 text-start">
                            <div className="mb-3">
                                {/* {response && <p className={userResponseType ? 'alert alert-success' : 'alert alert-danger'}>{response}</p>} */}
                                {alert && (
                                    <div className={`p-4 rounded-md ${alert.type === 'success' ? 'alert alert-success' : 'alert alert-danger'}`} role="alert">
                                        {alert.message}
                                    </div>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label signup__col--label">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    className="form-control py-3 shadow-none signup__col--inp"
                                    id="email"
                                    placeholder="Enter your email address"
                                    onChange={(e: any) => {
                                        setEmail(e.target.value)
                                    }}
                                />
                            </div>
                            {!email ? (
                                <div className="mb-3 py-5">
                                    <button className={`w-100 signup__col--disabled`} disabled type="button">
                                        Continue
                                    </button>
                                </div>
                            ) : (
                                <div className="mb-3 py-5">
                                    <button className={`w-100 signup__col--btn`} onClick={handleForgotPassword}>
                                        Continue
                                    </button>
                                </div>
                            )}
                            <div className=" text-center">
                                <p className="signup__col--label">
                                    <NavLink to="/login" style={{ color: '#2C4A8B', textDecoration: 'none', fontWeight: '600', fontSize: '14px' }}>
                                        <HiArrowLeft className="me-1" fontSize={18} /> Back to log in
                                    </NavLink>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ForgotPassword
