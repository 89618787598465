import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import * as serviceWorker from './serviceWorker'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-svg-core/styles.css'
import axios from 'axios';
import { FontStyles } from './resources/font-constants'
// Import slick carousel CSS files
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";


const root = createRoot(document.getElementById('root')!) // createRoot(container!) if you use TypeScript
// axios.defaults.baseURL = 'https://klakpad.net/api/api'
axios.defaults.baseURL = 'https://testing-temp.klakpad.dev/api/'
axios.interceptors.request.use(
    config => {
        config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    return config;
    }
)

root.render(
    <>
        <FontStyles />
        <App />
    </>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()



