import React, { useEffect } from 'react';
import { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Link, NavLink, redirect, useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { postRequest } from '../../utility/apiRequest';

const Signup: React.FC = () => {
    // const { userResponse, userResponseType, login,  setRoutePage } = useAuthContext();
    const [hideorshowPassword, setHideorShowPassword] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [userResponse, setUserResponse] = useState<string>('');
    const [userResponseType, setUserResponseType] = useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const agentCode = params.get('code');
    const duration = params.get('duration');
    const plansId = params.get('plan');
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Full name is required').min(5),
        username: Yup.string().required('Username is required').min(5),
        email: Yup.string().email('Invalid email Address').required('Email is required'),
        phone: Yup.string().required('Phone number is required'),
        password: Yup.string().required('Password is required'),
        about: Yup.string().oneOf(['Facebook', 'Instagram', 'Whatsapp', 'Friends', 'Others']),
        referralCode: Yup.string(),
        acceptTerms: Yup.boolean().oneOf([true], 'You must accept the terms and conditions').required('Required')
    });
console.log(process.env.REACT_APP_RESELLAR_URL);

    useEffect(() => {
        if (page === null) return navigate('/pricing?page=signup');
        console.log(agentCode);
    }, [page]);
    const handleSubmitForm = async (e: any, { resetForm }: any) => {
        setLoading(true);
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        //     return false;
        const result = await postRequest('register', {
            ...e,
            where: '',
            address: '',
            macAddress1: '',
            timezone: timeZone,
            country: '',
            macAddress2: '',
            currency_sign: '',
            password_confirmation: e.password,
            agent_id: agentCode ? agentCode : ''
        });
        if (result.data.success === false) {
            setUserResponseType(false);
            setLoading(false);
            setUserResponse(result.data?.message);
        }
        if (agentCode && result.data.agent_reg === true ) {
            
            const newAppUrl = `${process.env.REACT_APP_RESELLAR_URL}/dashboard`;
            // Open the new app in the same tab
            // window.open(newAppUrl, '_self');
            window.location.href = newAppUrl;
            setUserResponse('Registration successful!');
            // Close the current tab (will only work if initiated by user action)
           setTimeout(() => {
            window.close();
           }, 5000);
        }
        if (result.data.success === true) { 
            Cookies.set('userData', JSON.stringify(e));
            localStorage.setItem('token', result.data.api_token);
            setLoading(false);
            setUserResponseType(true);
            setUserResponse('Registration successful!');
            setTimeout(() => {
                setUserResponse('');
                resetForm();
                navigate(`/verify-phone?plan=${plansId}&duration=${duration}`);
            }, 5000);
        }
        if (result.data?.errors) {
            setLoading(false)
            setUserResponseType(false)
            setUserResponse(result.data?.message + ' ' + result.data?.errors)
            setTimeout(() => {
                setUserResponse('')
            }, 6000)
        }
        // if (result.data?.success === false) {
        //     setLoading(false)
        //     setUserResponseType(false)
        //     setUserResponse(result.data?.message)
        //     setTimeout(() => {
        //         setUserResponse('')
        //         resetForm()
        //     }, 6000)
        // }
    };
    const handleHideOrShowPassword = () => {
        setHideorShowPassword(!hideorshowPassword);
    };
    return (
        <>
            <div className="signup animate__animated">
                <div className="container-fluid">
                    <div className="row ">
                        <div className="col-md-6 col-sm-12 signup__col ">
                            <div className="ms-lg-4 mt-4 pt-3">
                                <NavLink to="/" className="signup__col--link pb-0 ps-lg-5">
                                    {' '}
                                    <img src="/assets/klakpad.svg" srcSet="" className="img-fluid signup__col--logo ms-lg-5 pb-4" alt="Logo" width={120} />
                                </NavLink>
                            </div>
                            <Formik
                                initialValues={{ name: '', email: '', phone: '', password: '', referralCode: '', about: '', username: '', acceptTerms: false }}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmitForm}
                            >
                                {props => {
                                    const { errors, dirty, touched, isValid, values } = props;
                                    return (
                                        <Form>
                                            <div className="row justify-content-center">
                                                <div className="col-md-8 "></div>
                                                <div className="col-md-8">
                                                    <h3 className="signup__col--title mt-5 mb-4 mx-1 fw-medium pt-4">Let's get your account set up.</h3>
                                                    <p className="signup__col--text  mx-2 pb-5">Signing up with Klakpad is simple and quick.</p>
                                                </div>
                                            </div>
                                            <div className="row  align-items-center justify-content-center">
                                                <div className="col-md-8">{userResponse && <p className={userResponseType ? 'alert alert-success' : 'alert alert-danger'}>{userResponse}</p>}</div>
                                                <div className="col-md-8 mb-3">
                                                    <label htmlFor="name" className="signup__col--label">
                                                        * Email address
                                                    </label>
                                                    <Field
                                                        type="text"
                                                        name="email"
                                                        id="email"
                                                        placeholder="Enter your email address"
                                                        className={
                                                            touched.email && errors.email
                                                                ? 'form-control  px-3 signup__col--inpisIvalid shadow-none'
                                                                : 'form-control  px-3 signup__col--inp shadow-none'
                                                        }
                                                    />
                                                    <ErrorMessage name="email" component="div" className="error text-danger signup__col--label" />
                                                </div>
                                                <div className="col-md-8 mb-3">
                                                    <label htmlFor="name" className="signup__col--label">
                                                        * Business name
                                                    </label>
                                                    <Field
                                                        type="text"
                                                        name="name"
                                                        id="name"
                                                        placeholder="Enter your business name"
                                                        className={
                                                            touched.name && errors.name ? 'form-control  px-3 signup__col--inpisIvalid shadow-none' : 'form-control  px-3 signup__col--inp shadow-none'
                                                        }
                                                    />
                                                    <ErrorMessage name="name" component="div" className="error text-danger signup__col--label" />
                                                </div>
                                                <div className="col-md-8 mb-3">
                                                    <label htmlFor="name" className="signup__col--label">
                                                        * Username
                                                    </label>
                                                    <Field
                                                        type="text"
                                                        name="username"
                                                        id="username"
                                                        placeholder="Enter username"
                                                        className={
                                                            touched.username && errors.username
                                                                ? 'form-control  px-3 signup__col--inpisIvalid shadow-none'
                                                                : 'form-control  px-3 signup__col--inp shadow-none'
                                                        }
                                                    />
                                                    <ErrorMessage name="username" component="div" className="error text-danger signup__col--label" />
                                                </div>
                                                <div className="col-md-8 mb-3">
                                                    <label htmlFor="name" className="signup__col--label">
                                                        * Phone number
                                                    </label>
                                                    <Field
                                                        type="text"
                                                        name="phone"
                                                        id="phone"
                                                        placeholder="Enter your phone number"
                                                        className={
                                                            touched.phone && errors.phone
                                                                ? 'form-control  px-3  signup__col--inpisIvalid shadow-none'
                                                                : 'form-control  px-3 signup__col--inp shadow-none'
                                                        }
                                                    />
                                                    <ErrorMessage name="phone" component="div" className="error text-danger signup__col--label" />
                                                </div>
                                                <div className="col-md-8 mb-3">
                                                    <label htmlFor="about" className="signup__col--label">
                                                        How did you hear about us?
                                                    </label>
                                                    <Field as="select" type="text" name="about" id="about" className="form-control custom-select  px-3 signup__col--inp signup__col--label shadow-none">
                                                        <option value="">Select all that applies</option>
                                                        <option value="Facebook">Facebook</option>
                                                        <option value="Instagram">Instagram</option>
                                                        <option value="Whatsapp ">Whatsapp</option>
                                                        <option value="Friends">From a friend</option>
                                                        <option value="Others">Others</option>
                                                    </Field>
                                                </div>
                                                <div className="col-md-8 mb-3">
                                                    <label htmlFor="name" className="signup__col--label">
                                                        Referer code
                                                    </label>
                                                    <Field type="text" name="referralCode" id="referralCode" placeholder="XXXX-XX-XXXXXX" className="form-control  px-3 signup__col--inp shadow-none" />
                                                </div>
                                                <div className="col-md-8 position-relative mb-3">
                                                    <label htmlFor="name" className="signup__col--label">
                                                        * Password
                                                    </label>
                                                    <Field
                                                        type={hideorshowPassword == false ? 'password' : 'text'}
                                                        name="password"
                                                        id="password"
                                                        placeholder="Enter your password"
                                                        className={
                                                            touched.password && errors.password
                                                                ? 'form-control px-3  signup__col--inpisIvalid shadow-none'
                                                                : 'form-control  px-3 signup__col--inp shadow-none'
                                                        }
                                                    />
                                                    <ErrorMessage name="password" component="div" className="error text-danger signup__col--label" />

                                                    <i
                                                        className={hideorshowPassword ? `fa-regular fa-eye signup__col--icon` : `fa-regular fa-eye-slash signup__col--icon`}
                                                        onClick={handleHideOrShowPassword}
                                                    />
                                                </div>
                                                <div className="col-md-8 mb-3">
                                                    <div className="pb-2 pt-2 signup__col--label d-flex align-items-flex">
                                                        {/* <div>
                                                            <label>
                                                                <Field type="checkbox" name="acceptTerms" id="acceptTerms" />I accept the terms and conditions
                                                            </label>
                                                            <ErrorMessage name="acceptTerms" component="div" className="error" />
                                                        </div> */}
                                                        <div className="">
                                                            <Field type="checkbox" name="acceptTerms" className="mx-2" />
                                                        </div>

                                                        <div>
                                                            <span>
                                                                By ticking the checkbox, you agree with our{' '}
                                                                <Link to="/terms-and-conditions" target="_blank" className="signup__col--link">
                                                                    Terms and Conditions
                                                                </Link>{' '}
                                                                and{' '}
                                                                <Link to="/privacy-policy" target="_blank" className="signup__col--link">
                                                                    Privacy Policy
                                                                </Link>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <ErrorMessage name="acceptTerms" component="div" className="error text-danger signup__col--label" />
                                                </div>

                                                <div className="col-md-8 mb-5">
                                                    {loading ? (
                                                        <button type="button" className="link btn fw-bold py-3 px-5 me-0 signup__col--btn " disabled>
                                                            <div className="spinner-border spinner-border-sm" role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </div>
                                                        </button>
                                                    ) : (
                                                        <button className={!(dirty && touched) ? 'disabled-btn signup__col--btn' : 'signup__col--btn'}>Get started</button>
                                                    )}
                                                </div>
                                                <div className="col-md-8  text-center">
                                                    <p className="signup__col--label">
                                                        Already have an account?{' '}
                                                        <NavLink to="/login" style={{ color: '#2C4A8B', textDecoration: 'none', fontWeight: '600' }}>
                                                            Login
                                                        </NavLink>
                                                    </p>
                                                </div>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                        <div
                            className="col-md-6 d-none d-lg-block"
                            style={{
                                backgroundImage: 'url(/assets/signup.png)',
                                height: '150vh',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover'
                            }}
                        ></div>
                    </div>
                </div>
            </div>
        </>
    );
};
Signup.propTypes = {
    errors: PropTypes.object.isRequired,
    dirty: PropTypes.bool.isRequired,
    touched: PropTypes.object.isRequired,
    isValid: PropTypes.bool.isRequired,
    values: PropTypes.object.isRequired
};
export default Signup;
