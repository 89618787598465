import Cookies from 'js-cookie'
import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { HiArrowLeft } from 'react-icons/hi'
import { postRequest } from '../../utility/apiRequest'


interface AlertProps {
    type: 'success' | 'error'
    message: string
}
const SetNewPassword: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [password, setPassword] = useState<string>()
    const [confirmPassword, setonfirmPassword] = useState<string>()
    const [alert, setAlert] = useState<AlertProps | null>(null)
    const [response, setResponse] = useState('')
    const navigate = useNavigate()
    const [hideorshowPassword, setHideorShowPassword] = useState<boolean>(false)

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required('Password is required')
           ,
        password_confirmation: Yup.string()
            .required('Password is required')
          
    })
    const email = localStorage.getItem('email' || [])

    const handleSetPassword = async (e: any) => {
        // setLoading(true)
        const result = await postRequest('update-forgot-password', { ...e, email })

        if (result.data.success === true) {
            setLoading(false)
            setResponse(result.data.message)
            setTimeout(() => {
                setAlert({
                    type: 'success',
                    message: `${result.data.message}`
                })

            }, 3000)

            setTimeout(() => {
                navigate('/login')
            }, 6000)
        }

        if (result.data.success === false) {
            setResponse(result.data.message)
            setTimeout(() => {
                setLoading(false)
            }, 3000)
            setResponse(result.message ? result.message : 'Encountered an error please try again')
        }
    }
    const handleHideOrShowPassword = () => {
        setHideorShowPassword(!hideorshowPassword)
    }
    return (
        <>
            <div className="verification py-5">
                <div className="container py-5">
                    <div className="row justify-content-center align-items-center text-center">
                        <div className="col-7">
                            <img src="/assets/lock.png" alt="logo" className="img-fluid mb-4" width={'60px'} />
                        </div>
                        <h3 className="signup__col--title">Set new password</h3>
                        <p className="pb-5 signup__col--text ">Enter your new password below</p>
                        {alert && (
                                    <div className={`p-4 rounded-md ${alert.type === 'success' ? 'alert alert-success' : 'alert alert-danger'}`} role="alert">
                                        {alert.message}
                                    </div>
                                )}
                        <Formik initialValues={{ password: '', password_confirmation: '' }} validationSchema={validationSchema} onSubmit={handleSetPassword}>
                            {(props) => {
                                const { errors, dirty, touched, isValid, values } = props
                                return (
                                    <div className="col-md-5">
                                        <Form>
                                            <div className="mb-4 text-start position-relative">
                                                <label htmlFor="name" className="signup__col--label">
                                                    Password
                                                </label>
                                                <Field
                                                    placeholder="Enter your password"
                                                    type={hideorshowPassword == false ? 'password' : 'text'}
                                                    name="password"
                                                    id="password"
                                                    
                                                    className={
                                                        touched.password && errors.password
                                                            ? 'form-control px-3  signup__col--inpisIvalid shadow-none'
                                                            : 'form-control  px-3 signup__col--inp shadow-none'
                                                    }
                                                />
                                                <ErrorMessage name="password" component="div" className="error text-danger signup__col--label" />
                                                <i
                                                        className={
                                                            hideorshowPassword
                                                                ? `fa-regular fa-eye signup__col--icon`
                                                                : `fa-regular fa-eye-slash signup__col--icon`
                                                        }
                                                        onClick={handleHideOrShowPassword}
                                                    />
                                            </div>
                                            <div className="mb-4 text-start">
                                                <label htmlFor="name" className="signup__col--label ">
                                                    Confirm Password
                                                </label>
                                                <Field
                                                    placeholder="Re-enter your password"
                                                    type={hideorshowPassword == false ? 'password' : 'text'}
                                                    name="password_confirmation"
                                                    id="password_confirmation"
                                                  
                                                    className={
                                                        touched.password_confirmation && errors.password_confirmation
                                                            ? 'form-control px-3  signup__col--inpisIvalid shadow-none'
                                                            : 'form-control  px-3 signup__col--inp shadow-none'
                                                    }
                                                />
                                                <ErrorMessage name="password_confirmation" component="div" className="error text-danger signup__col--label" />
                                            </div>
                                            <div className="mb-3 py-5">
                                                {loading ? (
                                                    <button type="button" className="link btn fw-bold py-3 px-5 me-0 signup__col--btn " disabled>
                                                        <div className="spinner-border spinner-border-sm" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                    </button>
                                                ) : (
                                                    <button
                                                        className={!(dirty && touched) ? 'disabled-btn signup__col--btn' : 'signup__col--btn'}
                                                        type="submit"
                                                    >
                                                        Reset password
                                                    </button>
                                                )}
                                            </div>
                                            <div className=" text-center">
                                                    <p className="signup__col--label">
                                                       
                                                        <NavLink to="/login" style={{ color: '#2C4A8B', textDecoration: 'none', fontWeight: '600', fontSize: '14px' }}>
                                                        <HiArrowLeft className='me-1' fontSize={18}/>  Back to log in
                                                        </NavLink>
                                                    </p>
                                                </div>
                                        </Form>
                                    </div>
                                )
                            }}
                        </Formik>
                        {/* <div className=" col-md-5 text-start">
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label">Password</label>
                                    <input type="password" 
                                    className="form-control py-3 shadow-none" 
                                    id="password" 
                                    placeholder="Enter your password" 
                                    onChange={(e:any) => {setPassword(e.target.value)}}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="cpassword" className="form-label">Confirm password</label>
                                    <input type="password"
                                     className="form-control py-3 shadow-none" 
                                     id="cpassword" 
                                     placeholder="Re-enter your password" 
                                     onChange={(e:any) => {setonfirmPassword(e.target.value)}}
                                     />
                                </div>
                                <div className="mb-3 py-5">
                                    <button className='w-100 signup__col--btn' onClick={handleSetPassword}>Reset password</button>
                                </div>
                            </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}
SetNewPassword.propTypes = {
    errors: PropTypes.object.isRequired,
    dirty: PropTypes.bool.isRequired,
    touched: PropTypes.object.isRequired,
    isValid: PropTypes.bool.isRequired,
    values: PropTypes.object.isRequired
}
export default SetNewPassword
